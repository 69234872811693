.createform-head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #4a90e2;
    color: rgb(226, 224, 224);
    padding: 8px 20px 8px 30px;
   
}

.createform-head .createform{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
}

.Formsbtnbox {
    display: flex;
    align-items: center;
}

.Formsbtnbox button {
    color: white;
    border: 1px solid white;
    margin: 0 10px;
    text-transform: capitalize;
    background-color: #629cdf;
    padding: 5px 10px;
    border-radius: 4px;
}
.Mandatorydiv{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 300px;
    padding:5px 10px;
    background-color: aliceblue;
}
.Mandatorydiv p{
    color: gray;
}
.FieldTypes{
    display: flex;
    margin: 20px 0 0 0;
}
.FieldTypes .p1{
    margin: 0 10% 0 3%;
    font-size: 16px;
}
.FieldTypes .p2{
    margin: 0 0 0 12%;
    font-size: 16px;
}

/*  */

.formbuilder{
    display: flex;
    justify-content: space-around;
}
.builder-sidebar_scroll{
    overflow: hidden !important;
    height: 90vh !important;
    width: 250px !important;
    display: flex;
    flex-direction: column;
}
.formcomponents{
    width: 250px;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
}
.card-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form-builder-panel{
    width: 240px;
    border: 1px solid lightgrey;
    display: none;
}
.form-builder-panel:first-child {
    display: block; 
}
.formcomponents .drag-copy{
    width: 90%;
    margin: 14px 0 0 0px !important;
    padding: 8px 10px !important;
    line-height: unset !important;
    font-size: 14px !important;
    background-color: aliceblue;
    color: #2c7bd5;
    font-weight: 500;
}
.builder-sidebar_search{
    display: none;
}

.formarea{
    width: 600px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    margin: 10px 0;
}
.formio-builder-form{
    height: 90vh;
    padding-top: 20px !important;
}
.formio-component-button{
    display: none;
} 
.builder-group-button{
    display: none;

}
