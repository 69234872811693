.addConfigBox{
    background-color: aliceblue;
    padding:10px 10px;
    border-radius: 6px;
    width: 200px;
    margin: 10px;
}
.addConfigBox .pt1{
    font-weight: bold;
    margin-bottom: 20px;
}
.addConfigBox .pt2{
    font-size: 12px;
    height: 110px;
}

.addConfigBox .p-dropdown {
    width: 100%;
    height: 35px;


}
.addConfigBox .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    height: 35px;
    padding: 0px 5px;
}
.addConfigBox .MuiStack-root .MuiInputBase-input {
    color: gray;
    font-size: 14px;

}