
.visible-master{
    padding:10px;
    box-shadow: 0 0 10px rgb(191, 187, 187);
    width: 270px;
    border-radius: 4px;
    display: block;
    position: absolute;
    background-color: white;
    color: rgb(72, 67, 67);
    top:100px;
    right: 10px;
    z-index: 9999;
    cursor: pointer;
}

.hidden-master{
    display: none;
}
.visible-master div{
    padding: 10px;
}
.visible-master div:hover{
    background-color: rgb(116, 163, 204) !important;
    color: white;
    border-radius: 4px;   
}