.fullbox{
    margin: 30px 20px;
    padding: 10px;
    box-shadow: 0 0 6px rgb(235, 231, 231);
    border-color: white;
    border-radius: 4px;
    
}
.dashboardboxdiv {
    display: flex;
}

.dashboard-card {
    width: auto;
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    border: none;
}

.dashboard-card .p-component {
    padding: 0;
    height: 38px;
    font-size: 12px;
    padding-left: 5px;

}

.dashboard-card label {
    font-size: 12px;
    color: gray;
    margin: 0;
}

/* .dashboard-card .p-calendar{
    width: 150px;
} */

.dashboard-card .p-button {
    border-radius: 0 6px 6px 0;
    background-color: skyblue;
    color: rgb(255, 255, 255);
    border: 1px solid skyblue;
}

.btnsearch {
    margin-left: 20px;
}

.dashboard-info {
    display: flex;
    justify-content: space-around;
    margin: 10px;
}

.dashboard-info .dashboard-totalemployees {
    background-color: aliceblue;
    padding: 10px;
    border-radius: 6px;
    width: 170px;

}

.dashboard-info .dashboard-totalemployees div {
    background-color: white;
    text-align: center;
    height: 100%;
    padding-top: 20px;
cursor: pointer;

}

.dashboard-info .dashboard-attendance {
    background-color: aliceblue;
    padding: 10px;
    border-radius: 6px;
}

.dashboard-info .dashboard-attendance .attendance-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.dashboard-info .dashboard-attendance .attendance-box div {
    background-color: white;
    width: 85px;
    height: 180px;
    text-align: center;
    margin: 0 5px;
    padding-top: 20px;
}
.dashboard-info .dashboard-attendance .attendance-box div a{
    text-decoration: none;
}

.emp-icon {
    background-color: aliceblue;
    color: rgb(76, 184, 226);
    padding: 6px;
    border-radius: 8px;
    width: 50px;
    height: 50px;
}

.ptags-empnumber {
    color: rgb(76, 184, 226);
    font-size: 30px;
    font-weight: 600;
}

.dashboard-heading {
    margin-left: 10px;
    font-weight: 500;
    color: rgb(57, 55, 55);
}

.checkedin-icon,
.holiday-icon,
.miscount-icon,
.notchecked-icon,
.shorthours-icon,
.onleave-icon,
.weeklyoff-icon,
.checked-icon {
    padding: 6px;
    border-radius: 8px;
    width: 45px;
    height: 45px;
}

.ptags-checkedinnumber,
.ptags-holidaynumber,
.ptags-miscountnumber,
.ptags-notcheckednumber,
.ptags-shorthoursnumber,
.ptags-onleavenumber,
.ptags-weeklyoffnumber,
.ptags-checkednumber {
    font-size: 30px;
    font-weight: 600;
}

.checkedin-icon {
    color: rgb(103, 164, 216);
}

.holiday-icon {
    color: rgb(88, 92, 93);
    background-color: rgb(238, 233, 233);
}

.miscount-icon {
    color: rgb(222, 34, 34);
}

.notchecked-icon {
    color: rgb(236, 86, 86);
    background-color: rgb(253, 223, 223);
}

.shorthours-icon {
    background-color: rgb(251, 232, 194);
}

.onleave-icon {
    color: rgb(42, 196, 42);
    background-color: rgb(219, 255, 219);
}

.weeklyoff-icon {
    color: rgb(88, 92, 93);
    background-color: rgb(238, 233, 233);
}

.checked-icon {
    background-color: rgb(249, 237, 214);
    color: rgb(255, 172, 5);
}

.ptags-checkedinnumber {
    color: rgb(103, 164, 216);
}

.ptags-holidaynumber {
    color: rgb(88, 92, 93);
}

.ptags-miscountnumber {
    color: rgb(103, 164, 216);
}

.ptags-notcheckednumber {
    color: rgb(248, 107, 107);
}

.ptags-shorthoursnumber {
    color: rgb(255, 172, 5);
}

.ptags-onleavenumber {
    color: rgb(42, 196, 42);
}

.ptags-weeklyoffnumber {
    color: rgb(88, 92, 93);
}

.ptags-checkednumber {
    color: rgb(255, 172, 5);
}

.ptags {
    font-size: 14px;
    color: gray;
    height: 40px;
    padding-top: 5px;
    margin: 0;
}

.dashboard-more {
    display: flex;
    justify-content: space-around;
    margin: 10px;
}

.dashboard-more .exceptions-pending {
    background-color: aliceblue;
    padding: 10px;
    border-radius: 8px;
}

.dashboard-more .exceptions-pending .exceptions-pending-heading {
    margin: 0 0 0 20px;
    font-size: 14px;
    font-weight: 500;
}

.dashboard-more .exceptions-pending .doublediv {
    display: flex;
    justify-content: space-around;
    margin: 5px 10px;
}

.dashboard-more .exceptions-pending .doublediv div {
    background-color: white;
    width: 200px;
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
}

.dashboard-more .exceptions-pending .doublediv div .double-ptag {
    margin: 0 0 0 5px;
    color: gray;
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
}

.dashboard-more .exceptions-pending .doublediv div .doubleum-ptag {
    color: rgb(103, 164, 216);
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 0 5px;

}

.canvas-source {
    background-color: aliceblue;
    padding: 10px;
    border-radius: 8px;
    width: 250px;
}

.canvas-source .canvas-source-heading {
    margin: 0 0 10px 20px;
    font-size: 14px;
    font-weight: 500;
}

.canvas-source .canvas-jschart {
    height: 240px !important;
    width: 240px !important;
    padding-right: 10px;

}

.canvas-source .canvas-jschart div {
    height: 240px !important;
    width: 230px !important;
}

.canvasjs-chart-credit {
    display: none;
}

.attendance-source {
    background-color: aliceblue;
    padding: 10px;
    border-radius: 8px;
    width: 400px;
}

.attendance-source .attendance-source-heading {
    margin: 0 0 0 20px;
    font-size: 14px;
    font-weight: 500;
}

.attendancesource-doublediv {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 5px 10px;
}

.attendancesource-doublediv div {
    background-color: white;
    width: 140px;
    border-radius: 8px;
    margin: 10px;
    padding: 10px;
}

.attendancesource-doublediv div .doubleptag {
    margin: 0 0 0 5px;
    color: gray;
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
}

.attendancesource-doublediv div .doubleumptag {
    color: rgb(103, 164, 216);
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 0 5px;

}

@media screen and (max-width:767px) {
    .fullbox {
        width: 97%;
        margin: 10px;
        padding: 10px;
    }

    .dashboardboxdiv {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .dashboard-card {
        width: 100% !important;

    }
    .dashboard-card .p-component{
        width: 100% !important;

    }
    .dashboard-card .p-button {
        width: 90px !important;
    }
    .btnsearch {
        margin: 20px 0;
        border-radius: 6px !important;
    }

    .dashboard-info {
        display: block;
        overflow-x: scroll;
        width: 100%;

    }

    .dashboard-info .dashboard-totalemployees {
        width: 100%;

    }

    .dashboard-more {
        display: block;
    }

    .dashboard-info .dashboard-attendance .attendance-box {
        display: flex;
        flex-wrap: wrap;
    }

    .dashboard-info .dashboard-attendance .attendance-box div {
        margin: 5px;
    }

    .canvas-source {
        width: 100%;
        margin: 10px;
    }

    .canvas-source .canvas-source-heading {
        margin: 0 0 10px 20px;
        font-size: 14px;
        font-weight: 500;
    }

    .canvas-source .canvas-jschart {
        height: 240px !important;
        width: 100% !important;
        padding-right: 0px;

    }

    .canvas-source .canvas-jschart div {
        height: 240px !important;
        width: 100% !important;
    }
    .dashboard-more .exceptions-pending {
        margin: 10px 0;
    }
    .attendance-source {
        width: 100%;
    }
    
    .attendancesource-doublediv div {
        width: 120px;
    }



}

@media screen and (min-width:768px) and (max-width:992px) {}