#main-div{
    width: 270px;
    position: fixed;
    left: 0;
    top: 0
}
.imgbox{
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imglogo{
    width: 170px;
}
.pro-sidebar .pro-menu::-webkit-scrollbar{
    display: none;
}
.sidebar-content-div {
    color: white !important;
    padding-left: 5px;
    overflow-y: scroll;
    height: 90vh;
    width: auto;
}
.sidebar-content-div .pro-inner-item{
    padding: 5px 35px 5px 20px !important; 
}

.sidebar-tb-item {
    color: white !important;
    font-size: 14px !important;
    margin-left: 10px;
}

@media screen and (max-width:767px) {
 
}

@media screen and (min-width:768px) and (max-width:992px){
 
}

/*  */
