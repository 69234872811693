
.openfilterdiv{
    padding:10px;
    box-shadow: 0 0 10px rgb(191, 187, 187);
    width: 500px;
    height: 400px;
    border-radius: 4px;
    display: block;
    position: absolute;
    background-color: white;
    color: rgb(72, 67, 67);
    top:170px;
    right: 10px;
    z-index: 9999;
}
.openfilterdiv p{
    text-align: center;
    border-bottom: 1px solid lightgray;
    font-size: 18px;
    padding: 10px 0;
    margin:-34px 0 10px 0;
}
.openfilterdiv b{
     display: flex;
     justify-content: flex-end;
     margin-top: 4px;
     cursor: pointer;
}
.hiddenfilterdiv{
    display: none;
}
.tabs-box{
    display: flex;
}
.tabslistdiv{
    width: 160px;
    display: flex;
    flex-direction: column;
}
.mangetab{
    padding: 10px;
    background-color:  #ffffff ;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: rgb(110, 107, 107);
    text-align: start;
}
.tabpanel-filter{
    width: 300px;
}
.tabpanel-filter .p-multiselect{
    width: 300px;
    height: 40px;
    margin-left: 10px;
}
.tabpanel-filter .p-multiselect .p-multiselect-label{
    padding:10px 4px 0 8px;
}

.p-connected-overlay-enter-done{
    z-index:999999 !important;
}

.openfilterdiv .buttones{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid lightgrey;
}
.openfilterdiv .buttones button{
    margin:10px 10px 0 10px;
    width: 130px;
    text-transform: capitalize;
}