.btnbox .btn {
    color: #237add;
    border: 1px solid white;
    margin: 0 10px;
    text-transform: capitalize;
    background-color: #ffffff;
    padding: 5px 10px;

}
.btnbox .btn:hover{
    color: #237add;
    background-color: #ffffff;
}