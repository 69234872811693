.cardflexauto {
    width: auto;
    margin: 0 1px;
}
.cardflexauto .p-calendar .p-inputtext{
    width: 370px;
}

/* clock label */
.cardflexauto label {
    font-size: 12px;
    color: gray;
    margin: 0;
}

/* clock label */
.cardflexauto .MuiStack-root {
    padding-top: 0;
}

.cardflexauto .p-button {
    border-radius: 0 6px 6px 0;
    background-color: skyblue;
    color: rgb(255, 255, 255);
    border: 1px solid skyblue;
}

/* clock label */
.cardflexauto .MuiStack-root {
    padding-top: 1px;

}

.cardflexauto .MuiStack-root .MuiInputBase-input {
    color: gray;
    width: 390px;

}