.Copymodalboxx {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 300px;
    border: 2px solid #ffffff;
    box-shadow:0 0 2px white;
    background-color: white;
    padding:20px;
    border-radius: 6px;
    z-index: 99999999999999;
}
.Copymodalboxx #Copy-title{
    margin:-25px 0 20px 0;
    text-align: center;
}
.Copymodalboxx b{
    display: flex;
    justify-content: flex-end;
}
.copyLinkageBox{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.Copycard{
    margin: 10px;
}
.Copycard input{
    width: 400px;
    height: 55px;
}
.Copycard .p-multiselect{
    width: 450px;
    height: 55px;
}
.copybtnbox{
    display: flex;
    justify-content: center;
    align-items: center;
}
.copybtnbox button{
    width: 120px;
    height: 40px;
}