.reportbox{
    box-shadow: 0 0 6px rgb(153, 151, 151);
    margin: 30px;
    padding: 20px;
    border-radius: 4px;
}
.reportbox div{
    margin: 10px 0 0 0;
}
.reportbox .tagline{
    font-size: 22px;
    font-weight: 400;
    margin-left: 5px;
}

.reportbox div button{
    margin: 20px 2px;
}
.btnboxs{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 !important;
}
.btnboxs button{
    margin: 20px 10px !important;
}