.Shifthome-mainbox {
    display: flex;
    background-color: transparent;
    /* justify-content: space-between; */
    /* width: 100%; */

}

/* .Shift-sidebarbox {
    width: 270px;
    position: fixed;
    left: 0;
    top: 0
} */

.Shift-rightbox {
    margin-left: 270px;
    width: 78.5%;
}

.Shift-second-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #4a90e2;
    color: rgb(237, 230, 230);
    padding: 8px 0 8px 30px;
    font-size: 14px;
    font-weight: 500;

}

.hamburg {
    display: flex;
    align-items: center;
}

.btnbox {
    display: flex;
    align-items: center;
}

.btnbox button {
    color: white;
    border: 1px solid white;
    margin: 0 10px;
    text-transform: capitalize;
    background-color: #629cdf;
    padding: 5px 10px;
}

.Shift-box {
    margin: 30px;
    padding: 10px;
    box-shadow: 0 0 6px rgb(208, 204, 204);
    border-color: white;
    border-radius: 4px;
    width: 94%;
}

.Shift-box .datagrid-width {
    width: 100%;
}

.Shift-box .heading-p {
    font-size: 22px;
    padding: 10px 0 5px 20px;
}

.filterDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    padding: 10px 30px 0 30px;
}
.filterDiv .selectdiv{
    padding-top: 15px;
}

.filterDiv div select {
    width: 100px;
    height: 35px;
    margin: 0px 5px;
    border-radius: 6px;
    padding: 4px;
}


.searchbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
}

.filterDiv .searchbox .input-div {
    display: flex;
    align-items: center;
    border: 1px solid gray;
    border-radius: 30px;
    padding: 5px 5px 0 5px;
    width: 320px;

}

.filterDiv .searchbox input {
    width: 320px;
    border: none;
    outline: none;
}


.filterDiv .searchbox input::placeholder {
    font-size: 14px;
    text-transform: capitalize;
}

.filterDiv .searchbox .img-div img {
    width: 30px;
    margin-left: 10px;
    background-color: #d6e4f5;
    border-radius: 6px;
    padding: 4px;
    cursor: pointer;
}

@media screen and (max-width:767px) {
    .Shift-sidebarbox {
        display: none;
    }

    .Shift-rightbox {
        margin: 5px;
        width: 740px;
        overflow-x: scroll;
    }

    .Shift-second-head {
        padding: 5px;
        overflow-x: scroll;
        width: 740px;

    }

    .filterDiv {
        width: 740px;
        padding: 5px;
    }

    .filterDiv .searchbox .input-div {
        width: 260px;
    }

    .filterDiv .searchbox input {
        width: 260px;
    }

    .Shift-box {
        width: 740px;
        margin: 10px;

    }

    .Shift-box .datagrid-width {
        width: 100%;
    }


}

@media screen and (min-width:768px) and (max-width:992px) {
   
    .Shift-rightbox {
        width: 900px;
    }

    .Shift-second-head {
        padding: 5px;
        overflow-x: scroll;
        width: 900px;

    }

    .filterDiv {
        width: 900px;
        padding: 5px;
    }

    .filterDiv .searchbox .input-div {
        width: 260px;
    }

    .filterDiv .searchbox input {
        width: 260px;
    }

    .Shift-box {
        width: 900px;
        margin: 10px;

    }

    .Shift-box .datagrid-width {
        width: 100%;
    }

}