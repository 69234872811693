
.home-mainbox {
    display: flex;
    background-color: transparent;
    /* justify-content: space-between; */
}

.rightbox{
    margin-left: 270px;
    width: 100%;
}
.second-head{
    display: flex;
    align-items: center;
    background-color: #4a90e2;
    color: rgb(226, 224, 224);
    padding: 8px 0 8px 30px;
    font-size: 14px;
    font-weight: 500;
}
.full-box{
    margin: 30px;
    padding: 10px;
    box-shadow: 0 0 6px rgb(224, 221, 221);
    border-color: white;
    border-radius: 4px;
}
.full-box .heading-p{
    font-size: 20px;
    padding: 10px 0 5px 20px;
    margin: 0;
}
.iconcolor{
    color: #4a90e2;
}
.reports{
    display: flex;
    justify-content: space-around;
}
.reports div a{
    text-decoration: none;
    height: auto;
}
.reports div p{
    font-size: 14px;
    font-weight: 500;
    color: rgb(82, 80, 80);
    margin: 0;
    padding: 5px 0;

}
.reports div h6{
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid rgb(220, 226, 228);
    color: rgb(65, 60, 60);
    padding-bottom: 10px;
    margin: 20px 0 0 0;
}


@media screen and (max-width:767px) {
    .rightbox{
        margin: 5px;
        width: 98%;
    }
    .full-box{
        margin:20px 10px;
        padding:10px 20px;
    }
    .full-box .heading-p{
        font-size: 20px;
        padding:5px;
    }
    .reports{
        flex-wrap: wrap;
        justify-content: unset;
    }
}

@media screen and (min-width:768px) and (max-width:992px){
    .reports{
        flex-wrap: wrap;
        justify-content: unset;
    }
    .reports div{
        width: 200px;
    }  
}