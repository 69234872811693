.inputdivcopy{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 400px;
    margin: 40px 0;
}
.inputdivcopy .inputarea input{
    width: 300px !important;
}
.inputdivcopy .inputarea button{
    border-radius: 5px;
    margin-left: 10px;
}

.inputdivcopy .inputarea .iconbutn{
   font-size: 20px;
   height:42px;
}