.inputareabox{
    display: flex;
    flex-wrap: wrap;
}
.inputareabox div{
    width: 400px;
    margin: 10px 20px;
}.inputareabox div input{
    height: 60px;
}
.form-label{
    font-size: 14px;
    color: gray;
}
.inputareabox div select{
    height: 60px;
    width: 400px;
    margin: 0 20px;

}