.CreateJourneyDiv{
    display: flex;
    justify-content: space-around;

}
.Employeeid{
    margin: 20px 0;
}
.Employeeid input{
    width: 350px;
}
.DateDiv{
    margin: 20px 0;
}
.DateDiv input{
    width: 350px;
}
.DateDiv button{
    border-radius: 0 6px 6px 0;
}
.Locationbtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}
.Locationbtn p{
    font-size: 18px;
    color: rgb(95, 91, 91);
}
.Locationbtn .butns{
    border-radius: 6px;
    color: white;
    padding:10px 8px;
}
.VisitTimeDiv{
    background-color: aliceblue;
    margin:20px 0 10px 0;
    padding: 30px 15px;
    border-radius: 6px;
}
.VisitTimeDiv p{
    font-size: 14px;
}