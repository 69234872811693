.selectionBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.card {
    width: 420px;
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    border: none;
}

.card label {
    font-size: 12px;
    color: gray;
    margin: 0;
}

.calenderbox {
    display: flex;
    align-items: center;
    margin: 10px 0;

}

.flex-auto {
    width: 190px;
    margin: 0 15px;
}

/* clock label */
.flex-auto label {
    font-size: 12px;
    color: gray;
    margin: 0;
}

/* clock label */
.flex-auto .MuiStack-root {
    padding-top: 0;
}

.flex-auto .p-button {
    border-radius: 0 6px 6px 0;
    background-color: skyblue;
    color: rgb(255, 255, 255);
    border: 1px solid skyblue;
}

.card .p-placeholder {
    font-size: 14px !important;
}

.flex-auto input {
    font-size: 14px !important;
}

.flex-auto input::placeholder {
    font-size: 14px !important;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.buttons .button1 {
    border-radius: 4px;
    margin: 0 10px;
    padding: 8px 10px;
    color: white;
    border: 2px solid #0ea5e9;
    letter-spacing: 1px;

}
.buttons .button2 {
    border-radius: 4px;
    margin: 0 10px;
    padding: 8px 10px;
    border: 2px solid rgb(60, 153, 216);
    color: rgb(60, 153, 216);
    letter-spacing: 1px;
}

.buttons button .p-button-label {
    font-weight: 500 !important;

}

@media screen and (max-width:767px) {
    .card {
        width: 98%;
    }

    .calenderbox {
        margin: 5px;

    }

    .flex-auto {
        width: 100%;
        margin: 5px;
    }

    .p-connected-overlay-enter-done {
        width: 95%;
    }
}

@media screen and (min-width:768px) and (max-width:992px) {}