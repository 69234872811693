.accountBox{
    background-color: aliceblue;
    padding:10px 10px;
    border-radius: 6px;
    width: 260px;
    margin: 10px;
}
.accountBoxwidth{
    background-color: aliceblue;
    padding:10px 10px;
    border-radius: 6px;
    width: 340px;
    margin: 10px;
}
.accountBoxwidth .p-dropdown{
    height: 35px;
}

.accountBox .p-dropdown {
    width: 100%;
    height: 35px;


}
.accountBox input{
    height: 35px;
    width: 100%;

}
.accountBox .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input{
    height: 35px;
    padding: 0px 5px;
}
.accountBox .MuiStack-root .MuiInputBase-input {
    color: gray;
    font-size: 14px;
}


.AccountInfo{
    width: 130px;
    margin: 10px 20px;
}
.img-logoDiv{
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
}
.img-logoDiv p{
    margin: 0;
    font-size: 10px;
}
.p-dropdown-items{
    padding-left: 0;
}