.VisitorInfo{
    display: flex;
    width: 220px;
    justify-content: space-around;
}
.VisitorInfo .Visitorphotoid{
    background-color: aliceblue;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
    width: 100px;
    color: rgb(147, 164, 238);
    cursor: pointer;
}
.VisitorInfo .Visitorphotoid .Visitor-p{
    font-size: 10px;
    font-weight: 600;
}