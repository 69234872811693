.headerbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid lightgray;
}
.heading-div p{
    font-weight: 600;
    color: rgb(138, 134, 134);
    margin: 0;
    padding:10px 20px;
}
.profilebox{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    padding:10px 20px;
    cursor: pointer;
}
.profilebox:hover{
    background-color: aliceblue;
    border-radius: 4px;
}
.profilebox div{
    padding: 0 6px;
}
.profilebox p{
    font-weight: 500;
    color: rgb(103, 101, 101);
    margin: 0;
}
.profilebox img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.visible-profile-popup{
    padding:10px;
    box-shadow: 0 0 10px rgb(191, 187, 187);
    width: 200px;
    border-radius: 4px;
    display: block;
    position: absolute;
    background-color: white;
    color: rgb(72, 67, 67);
    top:49px;
    right: 10px;
    z-index: 9999;
}

.hidden-profile-popup{
    display: none;
}
.visible-profile-popup div{
    margin:5px;
    padding: 8px 5px;
    font-size: 14px;
}
.visible-profile-popup a{
    text-decoration: none !important;
    color: rgb(82, 78, 78) !important;
}

.visible-profile-popup div:hover{
    background-color: rgb(116, 163, 204) !important;
    color: white;
    border-radius: 4px;   
}

@media screen and (max-width:767px) {
    .headerbox{
        padding:5px;
    }
    .heading-div p{
        font-weight: bold;
        color: gray;
        margin: 0;
        padding:10px 5px;
    }
    .profilebox{
        padding:10px 5px;
    }
    .visible-profile-popup{
        top:60px;
    }
   
}

@media screen and (min-width:768px) and (max-width:992px){
   
}