.cardwith2{
    /* width: 200px; */
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
    border: none;
}

/* .cardwith2 button{
    border-radius: 0 6px 6px 0;
    color: white;
} */