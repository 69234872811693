.tabslist-div{
    border-bottom: 1px solid lightgray;
    padding-left: 6%;
}
.mange-tab{
    padding: 6px 10px;
    background-color:  #ffffff ;
    border: none;
    margin:20px 10px 0 10px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(110, 107, 107);
    border-bottom: 2px solid white;
    text-align: start;
}
/* .mange-tab:hover{
  border-bottom: 2px solid #000000;
  color: #000000;
} */

.tabpanel0-div{
    box-shadow: 0 0 6px rgb(153, 151, 151);
    margin: 30px;
    padding: 20px;
    border-radius: 4px;
}
.profiletop-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
} 
.profiletop-div div{
    display: flex;
    align-items: center;
}
.profiletop-div div img{
    width: 60px;
    border-radius: 50%;

}
.profiletop-div div p{
    font-size: 28px;
    margin: 0 0 0 20px;
}
.profiletop-div .iconbox{
    box-shadow: 0 0 6px rgb(111, 107, 107);
    border-radius: 50%;
    padding: 10px;
    
}
.panelprofile-detailbox{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 30px 0;

}
.panelprofile-detailbox div{
    width: 200px;
    margin: 10px;
}
.panelprofile-detailbox div .heading-p{
    color: gray;
    font-size: 14px;
}
.panelprofile-detailbox div p{
    margin: 0;
}
.btndiv-panel{
    display: flex;
    justify-content: center;
    align-items: center;
}
.passwordbox{
    box-shadow: 0 0 6px rgb(153, 151, 151);
    margin: 30px;
    padding: 20px;
    border-radius: 4px;
}
.passwordbox div{
    margin: 10px 0 0 0;
}
.passwordbox .tagline{
    font-size: 22px;
    font-weight: 400;
    margin-left: 5px;
}
.passwordbox div input{
    width: 600px;
}
.passwordbox div button{
    margin: 20px 2px;
}


@media screen and (max-width:767px) {
    .profiletop-div div p{
        font-size: 20px;
        margin: 0 0 0 10px;
    }
    .passwordbox div input{
        width: 100% !important;
    }
}

@media screen and (min-width:768px) and (max-width:992px){
   
}