.modalboxx {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 350px;
    border: 2px solid #ffffff;
    box-shadow:0 0 2px white;
    background-color: white;
    padding:20px;
    border-radius: 6px;
    z-index: 99999999999999;
}
.modalboxx h2{
    margin-bottom: 20px;
    text-align: center;
}
.modalboxx b{
    display: flex;
    justify-content: flex-end;
}
.modalboxx .divfilebox{
    text-align: center;
    background-color: white;
    padding: 12px;
    border:1px dashed
    /* style={{ textAlign: 'center', backgroundColor: 'white', padding: '12px', borderColor: 'grey',border:'1px dashed' }} */
}
.modalboxx .divfilebox .fileptg{
    color: rgb(29, 125, 215);
    font-weight: 500;
    padding: 12px 0;
    cursor: pointer;
}
.modalboxx .divfilebox .lightgreytag{
    color: rgb(187, 184, 184);
}
.modalboxx .filebtnbox{
    display: flex;
    justify-content: center;
    margin-top:20px ;
}