.fielddate {
    display: flex;
    align-items: center;
}

.fielddate div {
    margin: 0 1px;
}

.fielddate .rs-input-group {
    width: 210px !important;

}

.fielddate div .daterange {
    font-size: 12px;
    color: gray;
    margin: 0;
    padding-top: 4px;
}

.time {
    width: 150px;
    margin: 0 15px;
}

/* clock label */
.time label {
    font-size: 12px;
    color: gray;
    margin: 0;
}

/* clock label */
.time .MuiStack-root {
    padding-top: 0;
}

.time .MuiStack-root .MuiInputBase-input {
    height: 0;
    color: gray;

}

/* .btnbox select{
    color: white !important;
    border: 1px solid white;
    margin: 0 10px;
    text-transform: capitalize;
    background-color: #629cdf;
    padding:0 2px;
    border-radius: 4px;
    height: 35px;
    display: flex;
    align-items: center;
} */

.visible-options{
    padding:10px;
    box-shadow: 0 0 10px rgb(191, 187, 187);
    width: 170px;
    border-radius: 4px;
    display: block;
    position: absolute;
    background-color: white;
    color: rgb(72, 67, 67);
    top:100px;
    right: 10px;
    z-index: 9999;
    cursor: pointer;
}

.hidden-options{
    display: none;
}
.visible-options div{
    padding: 10px;
}
.visible-options div:hover{
    background-color: rgb(116, 163, 204) !important;
    color: white;
    border-radius: 4px;   
}

.css-v9gfxr-MuiPaper-root {
    box-shadow: unset !important;
    border: 1px solid lightgrey;
    width: 290px !important;

}

.searchbox .search-inputdiv input {
    width: 240px;
    padding: 0px !important;

}

.css-1gtpq4r-MuiButtonBase-root-MuiIconButton-root {
    padding: 5px !important;
}

.openfilterdiv {
    padding: 10px;
    box-shadow: 0 0 10px rgb(191, 187, 187);
    width: 500px;
    height: 400px;
    border-radius: 4px;
    display: block;
    position: absolute;
    background-color: white;
    color: rgb(72, 67, 67);
    top: 170px;
    right: 10px;
    z-index: 9999;
}

.openfilterdiv p {
    text-align: center;
    border-bottom: 1px solid lightgray;
    font-size: 18px;
    padding: 10px 0;
    margin: -34px 0 10px 0;
}

.openfilterdiv b {
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
    cursor: pointer;
}

.hiddenfilterdiv {
    display: none;
}

.tabs-box {
    display: flex;
}

.tabslistdiv {
    width: 160px;
    height: 270px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
}

/* Style the scrollbar */
.tabslistdiv::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
}

/* Track */
.tabslistdiv::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color of the track */
}

/* Handle */
.tabslistdiv::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scrollbar */
    border-radius: 4px;
    /* Rounded corners */
}

/* Handle on hover */
.tabslistdiv::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color on hover */
}

.mangetab {
    padding: 10px;
    background-color: #ffffff;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: rgb(110, 107, 107);
    text-align: start;
    margin-right: 8px;
}

.tabpanel-filter {
    width: 300px;
}

.tabpanel-filter .p-multiselect {
    width: 300px;
    height: 40px;
    margin-left: 10px;
}

.tabpanel-filter .p-multiselect .p-multiselect-label {
    padding: 10px 4px 0 8px;
}

.p-connected-overlay-enter-done {
    z-index: 999999 !important;
}

.openfilterdiv .buttones {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid lightgrey;
    margin-top: 10px;
}

.openfilterdiv .buttones button {
    margin: 10px 10px 0 10px;
    width: 130px;
    text-transform: capitalize;
}