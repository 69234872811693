/* .rightboxoutlet{
    width: 100%;
    margin-left: 270px;
}

.rightboxoutlet-minimize{
    width: 100%;
    margin-left: 80px;
} */


@media screen and (max-width:767px) {
    .sidebar {
        display: none;
    }
}