.cardmode{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin:10px 20px;
    border-bottom: 1px solid rgb(236, 231, 231);
    padding-bottom: 30px;
}
.cardmode .mode .p1{
    margin: 0;
    font-size: 16px;
}
.cardmode div .p-selectbutton{
    display: flex;
}
.cardmode .mode .p2{
    margin: 0;
    font-size: 12px;
}
.cardmode div .p-button{
    height: 35px;
    font-size: 14px;
    padding: 0.50rem 1.0rem;
}
.cardmode .css-1aznpnh-MuiSlider-root{
    width: 92%;
    margin-left: 20px;
}
.cardmode .MuiSlider-markLabel{
    font-size: 12px;
    margin:0 10px;

}