.addLocationBox{
    background-color: aliceblue;
    padding:10px 10px;
    border-radius: 6px;
    width: 250px;
    margin: 10px;
}
.addLocationBox .pt1{
    font-weight: 500;
    margin-bottom: 0px;
}
.addLocationBox .pt2{
    font-size: 12px;
    margin-top: 20px;
    height: 110px;
}