.addmorebtn{
    margin: 30px 0;
}
.addmorebtn button{
    border-style: dashed !important;
    border: 1px solid gray;
    color: gray;
    text-transform: capitalize;
}
.cancel-submitbtn{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cancel-submitbtn button{
    margin: 10px;
}
.StructureOpen input{
    height: 35px;

}
.StructureOpen button{
    border-radius: 0 6px 6px 0;
    height: 35px;
}