.mainloginbox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: skyblue;
    height: 100vh;
}

.imgdiv-box {
    height: 450px;
    padding:0 10px;
}

.imgdiv-box img {
    height: 450px;
    border-radius: 4px;
    object-fit: cover;
    /* mix-blend-mode: color-burn; */
}

.boxform {
    width: 400px;
    height: 450px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 4px;
    background-color: white;
    padding: 0 30px;
}
.boxform div{
    margin: 5px 0;
}

.boxform .logodiv {
    background-color: rgb(184, 226, 242);
    padding: 20px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxform .logodiv img {
    width: 200px;
}

.boxform .ptag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.boxform .ptag p {
    font-size: 24px;
    color: gray;
    text-transform: uppercase;
}

.Forgotdiv {
    display: flex;
    justify-content: flex-end;

}

.Forgotdiv a {
    color: rgb(68, 65, 65);
    text-decoration: none;
    font-size: 14px;
}

.Forgotdiv a:hover {
    text-decoration: underline;

}

.loginbtn {
    display: flex;
    justify-content: center;

}

.loginbtn button {
    border-radius: 6px;
    width: 100%;
    color: white
}

@media screen and (max-width:767px) {
    .imgdiv-box {
        display: none;
    }
    .boxform {
        width: 90%;
        height: auto;
        padding:20px;
    }
}

@media screen and (min-width:768px) and (max-width:992px){
    .imgdiv-box {
        display: none;
    }
    .boxform {
        width: 70%;
        height: auto;
        padding:20px;
    }
}