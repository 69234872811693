.addmodalboxx b{
    display: flex ;
    justify-content: flex-end ;
}

.addplanfullbox{
    margin:10px;
    border-color: white;
    border-radius: 4px;
}

.addplanfullbox .Task-p{
    color: #0ea5e9;
    font-size: 20px;
    margin: -20px 0 0 10px;
}
.addmodalboxx{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 500px;
    overflow-y: scroll;
    border: 2px solid #ffffff;
    box-shadow:0 0 2px white;
    background-color: white;
    padding:20px;
    border-radius: 6px;
    z-index: 99999999999999;
}
.AddressDiv{
    height: 60px;
}
.AddressDiv p{
    margin-left: 4px;
}


.butns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.butns .buttn1 {
    border-radius: 4px;
    margin: 0 10px;
    padding: 8px 10px !important;
    color: white;
    border: 2px solid #0ea5e9;
    letter-spacing: 1px;

}
.butns .buttn2 {
    border-radius: 4px;
    margin: 0 10px;
    padding: 8px 10px;
    border: 2px solid rgb(60, 153, 216);
    color: rgb(60, 153, 216);
    background-color: white;
    letter-spacing: 1px;
}

.butns button .p-button-label {
    font-weight: 500 !important;

}


/* Style the scrollbar */
.addmodalboxx::-webkit-scrollbar {
    width: 8px;
    /* Width of the scrollbar */
}

/* Track */
.addmodalboxx::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color of the track */
}

/* Handle */
.addmodalboxx::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scrollbar */
    border-radius: 4px;
    /* Rounded corners */
}

/* Handle on hover */
.addmodalboxx::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color on hover */
}
